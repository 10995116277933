@charset "UTF-8";

.App {
    text-align: center;
}

@font-face {
    font-family: "VS-Script";
    src: local('SF'),
    url('font/VS-Script.ttf') format('truetype');
}

@font-face {
    font-family: "SF-heavy";
    src: local('SF'),
    url('font/SFBold.ttf') format('truetype');
}

@font-face {
    font-family: "SF-black";
    src: local('SF'),
    url('font/SFBlack.ttf') format('truetype');
}

@font-face {
    font-family: "SF-bold";
    src: local('SF'),
    url('font/SFHeavy.ttf') format('truetype');
}

@font-face {
    font-family: "SF-regular";
    src: local('SF'),
    url('font/SFRegular.ttf') format('truetype');
}

@font-face {
    font-family: "SF-thin";
    src: local('SF'),
    url('font/SFThin.ttf') format('truetype');
}

/* Style the accordion section */
.accordion__section {
    display: flex;
    flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
    background-color: #ccc;
}

/* Style the accordion content title */
.accordion__title {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 14px;
}

/* Style the accordion chevron icon */
.accordion__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
    transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
    background-color: white;
    overflow: hidden;
    transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 18px;
}

div::-webkit-scrollbar {
    width: 5px;
}

div::-webkit-scrollbar-track {
    border-radius: 5px;
}

div::-webkit-scrollbar-thumb {
    border-radius: 5px;
}

.own-modal {
    position: fixed;
    z-index: 980;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.own-modal-content {
    background-color: #fefefe;
    position: relative;
    margin: 5% auto;
    padding: 15px 15px 15px 15px;
    width: 80%;
    max-height: 80%;
    overflow: auto;
    border-radius: 15px;
}

.centerAll {
    align-items: center;
    justify-content: center;
}

.x_small-modal {
    width: 35%;
}

.small-modal {
    width: 40%;
}

.medium-modal {
    width: 60%;
}

.close-modal {
    color: #aaa;
    position: absolute;
    right: 15px;
    top: 0;
    font-size: 30px;
    font-weight: bold;
}

.close-modal:hover,
.close-modal:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.grad1 {
    background: linear-gradient(90deg, #439393 0%, #0D2131 100%);
}

.filter-blur1 {
    filter: blur(5px);
}

.filter-blur0 {
    filter: blur(0px);
}

div .row {
    margin: 0;
    padding: 0;
}

div .col {
    margin: 0;
    padding: 0;
}

div .grid {
    margin: 0;
    padding: 0;
}

.center {
    align-items: center;
    justify-content: center;
}

/*sub menus styles*/
.btn-menu-selected {
    height: 40px;
    background: white;
    border-radius: 0 15px 15px 0;
    align-items: center;
    justify-content: center;
    transition: background 1s;
}

.btn-menu {
    background: #1d2b34;
    height: 40px;
    align-items: center;
    justify-content: center;
    transition: background 1s;
}

.btn0-menu-selected {
    background: #1d2b34;
    transition: background 1s;
}

.btn0-menu {
    height: 40px;
    background: white;
    transition: background 1s;
}

.btn-menu-selected-top {
    height: 40px;
    border-radius: 0 0 0 15px;
    transition: border-radius 1s;
}

.btn-menu-selected-bottom {
    border-radius: 15px 0 0 0;
    transition: border-radius 1s;
}

/*both menus styles*/
.btn-both-selected-top {
    height: 40px;
    border-radius: 0 0 15px 15px;
    transition: border-radius 1s;
}

/*sub menus styles*/
.btn-sub-menu {
    padding: 0 10px !important;
    background: #1d2b34;
    height: 40px;
    align-items: center;
    justify-content: center;
    transition: border-radius 1s;
}


/*.btn-sub-menu-selected {*/
/*    padding: 0 10px !important;*/
/*    height: 40px;*/
/*    background: white;*/
/*    border-radius: 15px 0 0 15px;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    transition: border-radius 1s;*/
/*}*/

.btn-sub-menu.btn-sub-menu-selected {
    padding: 0 10px !important;
    height: 40px;
    background: #f1f1f1;
    border-radius: 15px 0 0 15px;
    align-items: center;
    justify-content: center;
    transition: border-radius 1s;
}

.btn0-sub-menu {
    height: 40px;
    background: white;
}


/*.btn0-sub-menu-selected {*/
/*    height: 40px;*/
/*    background: #1d2b34;*/
/*    transition: border-radius 1s;*/
/*}*/

.btn0-sub-menu.btn0-sub-menu-selected {
    height: 40px;
    background: #1d2b34;
    transition: border-radius 1s;
}

.btn-sub-menu-selected-top {
    height: 40px;
    border-radius: 0 0 15px 0;
    transition: border-radius 1s;
}

.btn-sub-menu-selected-bottom {
    border-radius: 0 15px 0 0;
    transition: border-radius 1s;
}

.text-sub-menu {
    text-align: left;
    color: white;
    font-family: "SF-regular";
    transition: color 1s;
}

.text-sub-menu-selected {
    text-align: left;
    color: #1d2b34;
    font-family: "SF-regular";
    transition: color 1s;
}

.logo_box {
    padding: 10px !important;
    border-radius: 15px;
}

.btn-normal {
    background: linear-gradient(90deg, #1d2b34, #1d2b34, #0D2131, #0D2A46);
}

.btn-normal {
    transition: 0.7s;
    background-size: 400% auto;
}

.btn-normal:hover {
    background-position: right center;
    text-decoration: none;
}


.btn-normal:hover .btn0-sub-menu {
    height: 40px;
    background: #1d2b34;
}

.bg-image {
    /* The image used */
    /*background-image: url("photographer.jpg");*/

    /* Add the blur effect */
    filter: blur(8px);
    -webkit-filter: blur(8px);

    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-text {
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
    color: white;
    font-weight: bold;
    border: 3px solid #f1f1f1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
}

@keyframes pulse {
    0% {
        transform: scale(0.65);
        box-shadow: 0 0 0 0 rgba(150, 150, 150, 0.7);
    }

    70% {
        transform: scale(0.7);
        box-shadow: 0 0 0 10px rgba(150, 150, 150, 0);
    }

    100% {
        transform: scale(0.65);
        box-shadow: 0 0 0 0 rgba(150, 150, 150, 0);
    }
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

.spinner {
    padding: 3px;
}

.spinner > div {
    width: 10px;
    height: 10px;
    background-color: #0D2A46;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.2s infinite ease-in-out both;
    animation: sk-bouncedelay 1.2s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
